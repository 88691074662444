<template>
  <div class="punchCalendar">
    <div class="cont">
      <div class="top">{{ studentTask.length }} <span>天</span></div>
      <van-calendar
        ref="calendar"
        title="日历"
        type="multiple"
        color="#3A78F9"
        readonly
        :default-date="defaultDate"
        :min-date="minDate"
        :max-date="maxDate" 
        :poppable="false"
        :show-confirm="false"
        @month-show="handChange"
      />
      <div class="integral">
        <div>
          <div>当前积分</div>
          <div class="num">{{ point }}</div>
        </div>
        <router-link
          :to="{
            path: '/shop/pointsmall/detail',
          }"
        >
          <div class="detailed">积分明细</div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import {
  queryStudentTask
} from "@/api/questionBank";
import {
  queryStudentInfoById
} from "@/api/personal";
export default {
  components: {},
  computed: {},
  watch: {},
  data () {
    return {
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(),
      studentTask: [],
      point: "",
      defaultDate: []
    };
  },
  mounted () {
    this.route = this.$route.query;
    this.getQueryStudentTask()
    this.getQueryStudentInfoById()
  },
  methods: {
    async getQueryStudentTask () {
      let parm =
        "?courseId=" +
        this.route.courseId +
        "&studentId=" +
        this.route.UserID
      const res = await queryStudentTask(parm);
      if (res.success == true) {
        this.studentTask = res.response.reverse();
        this.studentTask.forEach(element => {
          // this.defaultDate.push(new Date(element.TaskDate))
            this.defaultDate.push(new Date(this.common.dataYMD(element.TaskDate)))
        });
      }
    },
    // 积分
    async getQueryStudentInfoById () {
      let parm =
        "?id=" +
        this.route.UserID
      const res = await queryStudentInfoById(parm);
      if (res.success == true) {
        this.point = res.response.Point
      }
    },
    handChange (date, title) {
      // console.log(date, title, '')
    }

  }
};
</script>
<style lang="less" scoped>
.red {
  color: red !important;
}
.punchCalendar {
  min-height: 100vh;
  .cont {
    width: 100%;
    min-height: 153px;
    background: url("../../../assets/images/bg_h_pic.png");
    background-repeat: no-repeat;
    background-size: 100%;
    .top {
      font-size: 38px;
      font-family: DIN;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      padding: 30px 0 20px;
      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        background-color: #f8c057;
        border-radius: 50%;
        font-size: 11px;
        font-weight: normal;
      }
    }
    .integral {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px;
      background-color: #fff;
      padding: 20px 20px 20px 30px;
      border-radius: 10px;
      .num {
        font-size: 36px;
        color: #3a78f9;
        font-weight: bold;
        line-height: 1;
        margin-top: 15px;
      }
      .detailed {
        width: 66px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        color: #3a78f9;
        font-size: 12px;
        background-color: #e1eafc;
        border-radius: 20px;
      }
    }
  }
  /deep/.van-calendar {
    height: 400px;
    border-radius: 10px;
    margin: 0 20px;
    .van-calendar__header-title {
      display: none;
    }
  }
}
</style>
